<script>
import { VTextField } from 'vuetify/lib'

export default {
    name: 'NumTextField',
    extends: VTextField,
    props: ['maxLength'],
    methods: {
        onInput(e) {
            if(e && e.target) {
                e.target.value = this.filterNumber(e.target.value)
            }
        },
        filterNumber(str) {
            let fStr = str.replace(/[^0-9]/g,'')

            if (this.maxLength) {
                fStr = fStr.substring(0, this.maxLength)
            }

            this.$nextTick(() => {
                this.$emit('input', fStr)
            })
            return fStr
        },
    },
}
</script>